<template>
  <div>   
    <br>
    
     <b-row class="justify-content-center">
        <b-col cols="11"  md="10" lg="8" class="text-center">
           <!-- <center> 
                <b-row class="justify-content-center">
                   <b-col cols="10" md="5" style="background: #29486F; border-radius: 20px;"  v-if="!isMobile">  
                      <b-row class="justify-content-center" style=" margin: 10px;" >
                        <b-col cols="10" md="6" >  
                            <u style="color:#fff; font-size:14px; cursor: pointer;"  @click="tab = 0">GERENTE</u>
                        </b-col>
                        <b-col cols="10" md="1" v-if="isMobile" ></b-col>
                        <b-col cols="10" md="6"  >  
                            <u style="color:#fff; font-size:14px; cursor: pointer;" @click="tab = 1">CALLCENTER</u> 
                        </b-col> 
                      </b-row>  
                    </b-col>
                     <b-col cols="6" md="3" v-if="isMobile">
                        <v-btn :color="tab == 0 ? '#29486F' : '#1975B8'" :class="isMobile ? 'mt-3' : 'mt-2'"  size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="tab = 0"> 
                           GERENTE
                        </v-btn>
                     </b-col> 
                      <b-col cols="6" md="3" v-if="isMobile">
                        <v-btn :color="tab == 1 ? '#29486F' : '#1975B8'" :class="isMobile ? 'mt-3' : 'mt-2'"  size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="tab = 1"> 
                           CALLCENTER
                        </v-btn>
                      </b-col> 
                </b-row>
            </center> 
            <br><br> -->
            <center v-if="loader">
                <SpinnerLoader/>
                <p  style="color:#000;">Procesando información, no cierres el navegador <br> espera un momento...</p>
            </center>
            <br> 
                <template>
                    <h1 :style="isMobile ? 'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 45px;' : 
                             'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 50px;'">Callcenter Prepago</h1>
                    <br>
                    <div :class="isMobile ? 'table-responsive' :''">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                    <tr>
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td  style="color:#0b2739;  font-size:20px; width:3%;" ><b>Lugar</b></td> 
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>

                                        <td v-if="isMobile"></td>  
                                        <td style="color:#0b2739;  font-size:20px; width:7%;"><b>Callcenter</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td> 
                                        <!-- <td v-if="isMobile"></td>  -->
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <td v-if="isMobile" class="text-center" style="color:#0b2739;  font-size:20px; "><b>Cumplimiento</b></td> 
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <td style="color:#0b2739;  font-size:22px; width:16%;"><template v-if="!isMobile"><b>Cumplimiento</b></template></td>
                                        <!-- <td>DESAFÍOS</td> -->  
                                        <td v-if="isMobile"></td> 
                                         <td v-if="isMobile"></td> 
                                        <td style="color:#0b2739;  font-size:20px; width:6%;"><b>Puntos</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr v-for="(ran, index) in arrayPrepago2" :key="ran.id" >
                                        <td  class="text-center" :colspan="isMobile ? '4' : ''" >
                                            <b-row class="justify-content-center">
                                                <b-col cols="6" md="4" lg="5" >    
                                                        <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:120%; margin-top:-6px; ' 
                                                        : 'width:130%; margin-top:-10px;' ">  
                                                        <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                        <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:2px;' " > 
                                                                {{index + 1  }}
                                                        </h5>   
                                                </b-col>
                                                <b-col cols="6" md="4" lg="5" >    
                                                <!-- </td>
                                                <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                                    <template v-if="!ran.foto">
                                                        <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:110%; margin-top:-4px;' 
                                                        : 'width:110%; margin-top:-6px;' ">  
                                                    </template>
                                                    <template v-else>
                                                        <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:80%;  margin-top:-4px;' : 'width:130%; margin-top:-10px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                        </td> 
                                        <td :colspan="isMobile ? '4' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'">
                                            <div  :style="isMobile ? 'margin-top:-2px;' : 'margin-top:7px;'" >
                                                    {{ran.callcenter}} 
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                                <b style="font-size:13px;">
                                                    <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                                        <b-col cols="1"></b-col>
                                                        <b-col cols="5">
                                                            <b-row>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.abril}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>May</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.mayo}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.junio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.julio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col> 
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="5">
                                                            <b-row> 
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.agosto}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.septiembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.octubre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.noviembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>  
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="1"></b-col>
                                                    </b-row> 
                                                </b>
                                            </div>  
                                        </td> 
                                       <td  :colspan="isMobile ? '5' : ''"> 
                                            <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                                <b-col cols="10"  :style="isMobile ? 'margin-top:1px;' : ''">
                                                    <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                                    <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                                    <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                                </b-col>
                                                </b-row>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                    </div>  
                </template> 
                <template>
                    <h1 :style="isMobile ? 'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 45px;' : 
                             'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 50px;'">Callcenter Pospago</h1>
                    <br>
                    <div :class="isMobile ? 'table-responsive' :''">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                         <tr>
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td  style="color:#0b2739;  font-size:20px; width:3%;" ><b>Lugar</b></td> 
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>

                                        <td v-if="isMobile"></td>  
                                        <td style="color:#0b2739;  font-size:20px; width:7%;"><b>Callcenter</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td> 
                                        <!-- <td v-if="isMobile"></td>  -->
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <td v-if="isMobile" class="text-center" style="color:#0b2739;  font-size:20px; "><b>Cumplimiento</b></td> 
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <td style="color:#0b2739;  font-size:22px; width:16%;"><template v-if="!isMobile"><b>Cumplimiento</b></template></td>
                                        <!-- <td>DESAFÍOS</td> -->  
                                        <td v-if="isMobile"></td> 
                                         <td v-if="isMobile"></td> 
                                        <td style="color:#0b2739;  font-size:20px; width:6%;"><b>Puntos</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr v-for="(ran, index) in arrayPospago2" :key="ran.id" >
                                        <td  class="text-center" :colspan="isMobile ? '4' : ''" >
                                            <b-row class="justify-content-center">
                                                <b-col cols="6" md="4" lg="5" >    
                                                     <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:120%; margin-top:-6px; ' 
                                                        : 'width:130%; margin-top:-10px;' ">   
                                                        <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                        <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:2px;' " > 
                                                                {{index + 1  }}
                                                        </h5>   
                                                </b-col>
                                                <b-col cols="6" md="4" lg="5" >    
                                                <!-- </td>
                                                <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                                    <template v-if="!ran.foto">
                                                        <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:110%; margin-top:-4px;' 
                                                        : 'width:110%; margin-top:-6px;' ">  
                                                    </template>
                                                    <template v-else>
                                                        <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:80%;  margin-top:-4px;' : 'width:130%; margin-top:-10px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                        </td> 
                                        <td :colspan="isMobile ? '4' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'">
                                            <div  :style="isMobile ? 'margin-top:-2px;' : 'margin-top:7px;'" >
                                                    {{ran.callcenter}} 
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                                <b style="font-size:13px;">
                                                    <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                                        <b-col cols="1"></b-col>
                                                        <b-col cols="5">
                                                            <b-row>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.abril}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>May</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.mayo}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.junio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.julio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col> 
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="5">
                                                            <b-row> 
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.agosto}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.septiembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.octubre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.noviembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>  
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="1"></b-col>
                                                    </b-row> 
                                                </b>
                                            </div>  
                                        </td> 
                                       <td  :colspan="isMobile ? '5' : ''"> 
                                            <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                                <b-col cols="10"  :style="isMobile ? 'margin-top:1px;' : ''">
                                                    <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                                    <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                                    <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                                </b-col>
                                                </b-row>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                    </div>  
                </template>  
                <template>
                    <h1 :style="isMobile ? 'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 45px;' : 
                             'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 50px;'">Callcenter Asistido</h1>
                    <br>
                    <div :class="isMobile ? 'table-responsive' :''">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                      <tr>
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td  style="color:#0b2739;  font-size:20px; width:3%;" ><b>Lugar</b></td> 
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>

                                        <td v-if="isMobile"></td>  
                                        <td style="color:#0b2739;  font-size:20px; width:7%;"><b>Callcenter</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td> 
                                        <!-- <td v-if="isMobile"></td>  -->
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <td v-if="isMobile" class="text-center" style="color:#0b2739;  font-size:20px; "><b>Cumplimiento</b></td> 
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                         <td style="color:#0b2739;  font-size:22px; width:16%;"><template v-if="!isMobile"><b>Cumplimiento</b></template></td>
                                        <!-- <td>DESAFÍOS</td> -->  
                                        <td v-if="isMobile"></td> 
                                         <td v-if="isMobile"></td> 
                                        <td style="color:#0b2739;  font-size:20px; width:6%;"><b>Puntos</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr v-for="(ran, index) in arrayAsistido2" :key="ran.id" >
                                        <td  class="text-center" :colspan="isMobile ? '4' : ''" >
                                            <b-row class="justify-content-center">
                                                <b-col cols="6" md="4" lg="5" >    
                                                    <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:120%; margin-top:-6px; ' 
                                                        : 'width:130%; margin-top:-10px;' ">  
                                                        <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                        <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:2px;' " > 
                                                                {{index + 1  }}
                                                        </h5>   
                                                </b-col>
                                                <b-col cols="6" md="4" lg="5" >    
                                                <!-- </td>
                                                <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                                    <template v-if="!ran.foto">
                                                        <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:110%; margin-top:-4px;' 
                                                        : 'width:110%; margin-top:-6px;' ">  
                                                    </template>
                                                    <template v-else>
                                                        <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:80%;  margin-top:-4px;' : 'width:130%; margin-top:-10px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                        </td> 
                                        <td :colspan="isMobile ? '4' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'">
                                            <div  :style="isMobile ? 'margin-top:-2px;' : 'margin-top:7px;'" >
                                                    {{ran.callcenter}} 
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                                <b style="font-size:13px;">
                                                   <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                                        <b-col cols="1"></b-col>
                                                        <b-col cols="5">
                                                            <b-row>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.abril}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>May</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.mayo}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.junio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.julio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col> 
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="5">
                                                            <b-row> 
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.agosto}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.septiembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.octubre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.noviembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>  
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="1"></b-col>
                                                    </b-row> 
                                                </b>
                                            </div>  
                                        </td> 
                                       <td  :colspan="isMobile ? '5' : ''"> 
                                            <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                                <b-col cols="10"  :style="isMobile ? 'margin-top:1px;' : ''">
                                                    <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                                    <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                                    <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                                </b-col>
                                                </b-row>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                    </div>  
                </template>     
                <template>
                    <h1 :style="isMobile ? 'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 45px;' : 
                             'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 50px;'">Callcenter Migraciones</h1>
                    <br>
                    <div :class="isMobile ? 'table-responsive' :''">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                     <tr>
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td  style="color:#0b2739;  font-size:20px; width:3%;" ><b>Lugar</b></td> 
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>

                                        <td v-if="isMobile"></td>  
                                        <td style="color:#0b2739;  font-size:20px; width:7%;"><b>Callcenter</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td> 
                                        <!-- <td v-if="isMobile"></td>  -->
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <td v-if="isMobile" class="text-center" style="color:#0b2739;  font-size:20px; "><b>Cumplimiento</b></td> 
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                       <td style="color:#0b2739;  font-size:22px; width:16%;"><template v-if="!isMobile"><b>Cumplimiento</b></template></td>
                                        <!-- <td>DESAFÍOS</td> -->  
                                        <td v-if="isMobile"></td> 
                                         <td v-if="isMobile"></td> 
                                        <td style="color:#0b2739;  font-size:20px; width:6%;"><b>Puntos</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr v-for="(ran, index) in arrayMigraciones2" :key="ran.id" >
                                        <td  class="text-center" :colspan="isMobile ? '4' : ''" >
                                            <b-row class="justify-content-center">
                                                <b-col cols="6" md="4" lg="5" >    
                                                     <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:120%; margin-top:-6px; ' 
                                                        : 'width:130%; margin-top:-10px;' ">  
                                                        <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                        <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:2px;' " > 
                                                                {{index + 1  }}
                                                        </h5>   
                                                </b-col>
                                                <b-col cols="6" md="4" lg="5" >    
                                                <!-- </td>
                                                <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                                    <template v-if="!ran.foto">
                                                        <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:110%; margin-top:-4px;' 
                                                        : 'width:110%; margin-top:-6px;' ">  
                                                    </template>
                                                    <template v-else>
                                                        <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:80%;  margin-top:-4px;' : 'width:130%; margin-top:-10px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                        </td> 
                                        <td :colspan="isMobile ? '4' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'">
                                            <div  :style="isMobile ? 'margin-top:-2px;' : 'margin-top:7px;'" >
                                                    {{ran.callcenter}} 
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                                <b style="font-size:13px;">
                                                     <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                                        <b-col cols="1"></b-col>
                                                        <b-col cols="5">
                                                            <b-row>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.abril}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>May</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.mayo}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.junio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.julio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col> 
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="5">
                                                            <b-row> 
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.agosto}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.septiembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.octubre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.noviembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>  
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="1"></b-col>
                                                    </b-row> 
                                                </b>
                                            </div>  
                                        </td> 
                                       <td  :colspan="isMobile ? '5' : ''"> 
                                            <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                                <b-col cols="10"  :style="isMobile ? 'margin-top:1px;' : ''">
                                                    <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                                    <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                                    <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                                </b-col>
                                                </b-row>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                    </div>  
                </template> 
                <template>
                    <h1 :style="isMobile ? 'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 45px;' : 
                             'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 50px;'">Callcenter Upsell</h1>
                    <br>
                    <div :class="isMobile ? 'table-responsive' :''">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                     <tr>
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td  style="color:#0b2739;  font-size:20px; width:3%;" ><b>Lugar</b></td> 
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>

                                        <td v-if="isMobile"></td>  
                                        <td style="color:#0b2739;  font-size:20px; width:7%;"><b>Callcenter</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td> 
                                        <!-- <td v-if="isMobile"></td>  -->
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <td v-if="isMobile" class="text-center" style="color:#0b2739;  font-size:20px; "><b>Cumplimiento</b></td> 
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <td style="color:#0b2739;  font-size:22px; width:16%;"><template v-if="!isMobile"><b>Cumplimiento</b></template></td>
                                        <!-- <td>DESAFÍOS</td> -->  
                                        <td v-if="isMobile"></td> 
                                         <td v-if="isMobile"></td> 
                                        <td style="color:#0b2739;  font-size:20px; width:6%;"><b>Puntos</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr v-for="(ran, index) in arrayUpsell2" :key="ran.id" >
                                        <td  class="text-center" :colspan="isMobile ? '4' : ''" >
                                            <b-row class="justify-content-center">
                                                <b-col cols="6" md="4" lg="5" >    
                                                    <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:120%; margin-top:-6px; ' 
                                                        : 'width:130%; margin-top:-10px;' ">  
                                                        <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                        <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:2px;' " > 
                                                                {{index + 1  }}
                                                        </h5>   
                                                </b-col>
                                                <b-col cols="6" md="4" lg="5" >    
                                                <!-- </td>
                                                <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                                    <template v-if="!ran.foto">
                                                        <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:110%; margin-top:-4px;' 
                                                        : 'width:110%; margin-top:-6px;' ">  
                                                    </template>
                                                    <template v-else>
                                                        <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:80%;  margin-top:-4px;' : 'width:130%; margin-top:-10px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                        </td> 
                                        <td :colspan="isMobile ? '4' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'">
                                            <div  :style="isMobile ? 'margin-top:-2px;' : 'margin-top:7px;'" >
                                                    {{ran.callcenter}} 
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                                <b style="font-size:13px;">
                                                   <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                                        <b-col cols="1"></b-col>
                                                        <b-col cols="5">
                                                            <b-row>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.abril}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>May</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.mayo}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.junio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.julio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col> 
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="5">
                                                            <b-row> 
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.agosto}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.septiembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.octubre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.noviembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>  
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="1"></b-col>
                                                    </b-row> 
                                                </b>
                                            </div>  
                                        </td> 
                                       <td  :colspan="isMobile ? '5' : ''"> 
                                            <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                                <b-col cols="10"  :style="isMobile ? 'margin-top:1px;' : ''">
                                                    <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                                    <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                                    <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                                </b-col>
                                                </b-row>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                    </div>  
                </template> 
                <template>
                    <h1 :style="isMobile ? 'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 45px;' : 
                             'color:#0b2739; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 50px;'">Callcenter Renovaciones</h1>
                    <br>
                    <div :class="isMobile ? 'table-responsive' :''">
                                <table class="table table-borderless mt-3 text-center">
                                    <thead class="text-white">
                                        <tr>
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td  style="color:#0b2739;  font-size:20px; width:3%;" ><b>Lugar</b></td> 
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>

                                        <td v-if="isMobile"></td>  
                                        <td style="color:#0b2739;  font-size:20px; width:7%;"><b>Callcenter</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td> 
                                        <!-- <td v-if="isMobile"></td>  -->
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <!-- <td v-if="isMobile"></td> -->
                                        <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <td v-if="isMobile" class="text-center" style="color:#0b2739;  font-size:20px; "><b>Cumplimiento</b></td> 
                                          <td v-if="isMobile"></td>
                                            <td v-if="isMobile"></td>
                                          <td v-if="isMobile"></td>
                                        <!-- <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td>
                                        <td v-if="isMobile"></td> -->
                                        <td style="color:#0b2739;  font-size:22px; width:16%;"><template v-if="!isMobile"><b>Cumplimiento</b></template></td>
                                        <!-- <td>DESAFÍOS</td> -->  
                                        <td v-if="isMobile"></td> 
                                         <td v-if="isMobile"></td> 
                                        <td style="color:#0b2739;  font-size:20px; width:6%;"><b>Puntos</b></td>
                                        <td v-if="isMobile"></td> 
                                        <td v-if="isMobile"></td>  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <tr v-for="(ran, index) in arrayRenovaciones2" :key="ran.id" >
                                        <td  class="text-center" :colspan="isMobile ? '4' : ''" >
                                            <b-row class="justify-content-center">
                                                <b-col cols="6" md="4" lg="5" >    
                                                     <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:120%; margin-top:-6px; ' 
                                                        : 'width:130%; margin-top:-10px;' ">  
                                                        <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                        <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:2px;' " > 
                                                                {{index + 1  }}
                                                        </h5>   
                                                </b-col>
                                                <b-col cols="6" md="4" lg="5" >    
                                                <!-- </td>
                                                <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                                    <template v-if="!ran.foto">
                                                        <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:110%; margin-top:-4px;' 
                                                        : 'width:110%; margin-top:-6px;' ">  
                                                    </template>
                                                    <template v-else>
                                                        <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded'" alt="Image" 
                                                        :style="!isMobile ? 'width:80%;  margin-top:-4px;' : 'width:130%; margin-top:-10px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                        </td> 
                                        <td :colspan="isMobile ? '4' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'">
                                            <div  :style="isMobile ? 'margin-top:-2px;' : 'margin-top:7px;'" >
                                                    {{ran.callcenter}} 
                                            </div>  
                                        </td>
                                        <td :colspan="isMobile ? '10' : ''">
                                            <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                                <b style="font-size:13px;">
                                                    <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                                        <b-col cols="1"></b-col>
                                                        <b-col cols="5">
                                                            <b-row>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.abril}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>May</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.mayo}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                        <b>{{ran.junio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.julio}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col> 
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="5">
                                                            <b-row> 
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.agosto}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.septiembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.octubre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>
                                                                <b-col cols="3">
                                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:16px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                        <b>{{ran.noviembre}}</b> 
                                                                    </h4>
                                                                    <br>
                                                                    <br>
                                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">
                                                                </b-col>  
                                                            </b-row>
                                                        </b-col>
                                                        <b-col cols="1"></b-col>
                                                    </b-row> 
                                                </b>
                                            </div>  
                                        </td> 
                                       <td  :colspan="isMobile ? '5' : ''"> 
                                            <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                                <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                                <b-col cols="10"  :style="isMobile ? 'margin-top:1px;' : ''">
                                                    <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                                    <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                                    <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                                </b-col>
                                                </b-row>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                    </div>  
                </template>  
            <br>
            <span  @click="subirArriba()" class="mdi mdi-arrow-up-thick " style="color:#fff; cursor: pointer;"> Regresar al Inicio</span> 
            <br>
        </b-col>
     </b-row>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}

import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Ranking',
  components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayRanking:[],
      arrayRanking2:[],
      tab:0,
      bases:{},
      arrayCamp:['prepago','pospago','asistido','migraciones','upsell','renovaciones'],
      colors:['#83c32d','#83c32d','#83c32d','#5ea7dd','#5ea7dd'],
      arrayPrepago:[],
      arrayPospago:[],
      arrayAsistido:[],
      arrayMigraciones:[],
      arrayUpsell:[],
      arrayRenovaciones:[],
      arrayPrepago2:[],
      arrayPospago2:[],
      arrayAsistido2:[],
      arrayMigraciones2:[],
      arrayUpsell2:[],
      arrayRenovaciones2:[]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  methods:{
    subirArriba(){
      // this.$scrollTo("#subir");
       window.scrollTo(0, -500);
    },
    getcampanas(){
      this.loader = true; 
       var url= 'auth/rankintlmkt?email=no';
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                setTimeout(() => {
                    this.bases = data.base;
                    this.arrayPrepago = data.prepago.sort(compare);
                    this.arrayPospago = data.pospago.sort(compare);
                    this.arrayAsistido = data.asistido.sort(compare);
                    this.arrayMigraciones = data.migraciones.sort(compare);
                    this.arrayUpsell = data.upsell.sort(compare);
                    this.arrayRenovaciones = data.renovaciones.sort(compare);
                    this.arrayPrepago2 = data.prepago2.sort(compare);
                    this.arrayPospago2 = data.pospago2.sort(compare);
                    this.arrayAsistido2 = data.asistido2.sort(compare);
                    this.arrayMigraciones2 = data.migraciones2.sort(compare);
                    this.arrayUpsell2 = data.upsell2.sort(compare);
                    this.arrayRenovaciones2 = data.renovaciones2.sort(compare);
                    this.loader = false;
                  }, 1000);
                
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/"); 
    }
  },
  mounted(){
    this.$bus.$emit("cambia_fondo", true)
    this.getcampanas();
  }
}
</script>
<style>
@import url('https://fonts.cdnfonts.com/css/guthen-bloots-personal-use');

.flexbox {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}
</style>