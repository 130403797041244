<template>
  <div>    
    <br v-if="isMobile"><br v-if="isMobile">
    <b-row class="justify-content-center"> 
        <b-col cols="9" md="4" lg="10" style="margin-top: -57px;">
           <center><img  src="elementos/movi.png" :class="isMobile ? 'rounded float-center' : 'rounded  float-right'" alt="Image" :style="isMobile ? 'width: 25%;' : 'width: 10%;'"></center> 
        </b-col>
        <b-col cols="12" v-if="isMobile" ></b-col>
        <b-col cols="8" md="6" lg="10" style="margin-top: -72px;">
          <br v-if="isMobile"><br v-if="isMobile"><br v-if="isMobile">         
          <center><img src="elementos/logos.png" alt="Image" :style="isMobile ? 'margin-left: -35px; width: 135%;' : '-510px'"   :class="isMobile ? 'float-center' : 'float-left'"></center> 
        </b-col>
        <b-col cols="1" v-if="isMobile" ></b-col>
        <b-col cols="12" v-if="isMobile" ></b-col>
        <b-col cols="8" md="3"  lg="10" :style="isMobile ? 'margin-top: 22px;' : 'margin-top: -57px; margin-left: -60px;'"> 
          <p :class="isMobile ? 'text-white text-center' : 'text-white text-right'" > 
                <span style="font-size:15px; color:#fff; cursor:pointer; " @click="regresarHome()">
                  <img  src="elementos/hom.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image"
                    :style="!isMobile ? 'width:2%; cursor:pointer;  margin-top:-10px;' : 'width:10%; cursor:pointer; margin-top:-10px;'"  >
                    &nbsp;&nbsp; Inicio
                </span>  
          </p>
        </b-col>
        <b-col cols="12" v-if="isMobile" ></b-col>
        <b-col cols="8" md="7" class="text-center mt-1" :style="isMobile ? 'margin-top:-20px;' : 'margin-top:-20px;'">
            <br v-if="!isMobile">
            <br v-if="!isMobile"> 
            <b-row class="justify-content-center"> 
               <b-col cols="11" md="6" lg="3"> 
                    <v-btn :color="canal == 3 ? '#5cb615' : '#8d3fe1'" id="menus" :small="isMobile ? true: false" :class="isMobile ? 'mt-5' : 'mt-2'" block  dark style="text-transform:capitalize; border-radius: 18px; font-size:17px;" 
                     @click="cambiaCanal(3)"> 
                        <span class="ml-3">Propio</span>
                    </v-btn> 
               </b-col> 
               <b-col cols="11" md="6" lg="3"> 
                    <v-btn  :color="canal == 4 ? '#5cb615' : '#8d3fe1'"  id="menus" :small="isMobile ? true: false" :class="isMobile ? 'mt-5' : 'mt-2'" block dark   style="text-transform:capitalize; border-radius: 18px; font-size:17px;"
                     @click="cambiaCanal(4)"> 
                      <span class="ml-3">Especialista</span>
                    </v-btn>           
               </b-col>
               <b-col cols="11" md="6" lg="3"> 
                    <v-btn :color="canal == 1 ? '#5cb615' : '#8d3fe1'"  id="menus" :small="isMobile ? true: false" :class="isMobile ? 'mt-5' : 'mt-2'" block dark   style="text-transform:capitalize; border-radius: 18px; font-size:17px;" 
                     @click="cambiaCanal(1)"> 
                      <span class="ml-3">Retail</span>
                    </v-btn> 
              </b-col>
              <b-col cols="11" md="6" lg="3">
                    <v-btn :color="canal == 2? '#5cb615' : '#8d3fe1'"  id="menus" :small="isMobile ? true: false" :class="isMobile ? 'mt-5' : 'mt-2'" block dark   style="text-transform:capitalize; border-radius: 18px; font-size:17px;"
                     @click="cambiaCanal(2)"> 
                        <span class="ml-3">TLMKT</span>
                    </v-btn> 
              </b-col> 
            </b-row>
        </b-col>
    </b-row> 
    <br><br><br>
    <template v-if="canal == 2">
      <tlmkt2></tlmkt2> 
    </template>
    <template v-if="canal == 3">
      <propio2></propio2>
    </template>
   <template v-if="canal == 1">
      <retail2></retail2> 
    </template>
    <template v-if="canal == 4">
      <especialista2></especialista2> 
    </template>
     <!-- <center  >
        <br v-if="isMobile"> 
      <img :src="isMobile ? 'img/ganadores.png' : 'img/ganas.png'" class="rounded" alt="Image" :style="isMobile ? 'width:100%;' : ' margin-top: -420px;'" >
     </center>  -->
  </div>
</template>

<script>
import propio2 from './Propio2.vue';
import tlmkt2 from './Tlmkt2.vue';
import retail2 from './Retail2.vue';
import especialista2 from './Especialista2.vue';
export default {
  name: 'Ranking',
  components:{
    tlmkt2,
    propio2,
    retail2,
    especialista2
  },
  data(){
    return{
      isMobile: this.$mobile(),
      canal:3
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  methods:{
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/embajador"); 
    },
    cambiaCanal(id){
      this.canal = id;
      this.apibitacora();
    },
    apibitacora(){
      var seccion = "";
      var can = "";
      switch (this.canal) {
        case 1:
          seccion = 'Visualizo ranking retail';
          can = "Retail";
        break; 
        case 2:
          seccion = 'Visualizo ranking TLMKT';
           can = "TLMKT";
        break;
        case 3:
          seccion = 'Visualizo ranking propio';
           can = "Propio";
        break;
        case 4:
          seccion = 'Visualizo ranking especialista';
           can = "Especialista";
        break; 
      }
     this.$api.post('auth/bitacora', {
          'correo' : '',
          'mrt' : '',
          'canal' : can,
          'perfil' : 'Embajadores',
          'region' : 'N/A',
          'url' :  '/ranking-embajador',
          'seccion' : 'Ranking',
          'accion' : "Visualizar",
          'detalle' :  seccion,
        }).then(
          response => {
             console.log(response);
          })
    },
  },
  mounted(){
    this.apibitacora();
  }
}
</script>