<template>
  <div>     
     <b-row class="justify-content-center">
        <b-col cols="11" md="11" lg="8" class="text-center">
            <center> 
                <b-row class="justify-content-center">
                   <b-col cols="10" md="6" style="background: #29486F; border-radius: 20px;"  v-if="!isMobile">  
                      <b-row class="justify-content-center" style=" margin: 5px;" >
                        <b-col cols="10" md="3" class="mt-2">  
                            <u style="color:#fff; font-size:14px; cursor: pointer;"  @click="cambiaTab(0)">IDPDV</u>
                        </b-col>
                        <b-col cols="10" md="3" class="mt-2">  
                            <u style="color:#fff; font-size:14px; cursor: pointer;"  @click="cambiaTab(3)">EJECUTIVO</u>
                        </b-col>
                        <b-col cols="10" md="3" class="mt-2" >  
                            <u style="color:#fff; font-size:14px; cursor: pointer;" @click="cambiaTab(2)">COORDINADOR</u>
                        </b-col>  
                        <b-col cols="10" md="3"  class="mt-1">  
                           <input v-model="buscar_gerente" type="text" class="form-control" placeholder="Buscar"  v-if="tab == 0"  style="border-radius: 10px; height: 90%;">
                           <input v-model="buscar_coordinador" type="text" class="form-control" placeholder="Buscar" v-if="tab == 2"  style="border-radius: 10px;  height: 90%;" >
                        </b-col> 
                      </b-row>  
                    </b-col>
                     <b-col cols="12" md="3" v-if="isMobile">
                        <v-btn :color="tab == 0 ? '#29486F' : '#1975B8'" :class="isMobile ? 'mt-4' : 'mt-2'"  size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaTab(0)"> 
                           IDPDV
                        </v-btn>
                     </b-col> 
                     <b-col cols="12" md="3" v-if="isMobile">
                        <v-btn :color="tab == 3 ? '#29486F' : '#1975B8'" :class="isMobile ? 'mt-4' : 'mt-2'"  size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaTab(3)"> 
                           Ejecutivo
                        </v-btn>
                     </b-col> 
                      <b-col cols="12" md="3" v-if="isMobile">
                        <v-btn :color="tab == 2 ? '#29486F' : '#1975B8'" :class="isMobile ? 'mt-4' : 'mt-2'"  size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaTab(2)"> 
                           Coordinador
                        </v-btn>
                      </b-col>
                      <b-col cols="8" md="3"  v-if="isMobile"> 
                         <input v-model="buscar_gerente" type="text" class="form-control mt-4" placeholder="Buscar"  v-if="tab == 0"  style="border-radius: 10px;  height: 75%;" >
                         <input v-model="buscar_coordinador" type="text" class="form-control mt-4" placeholder="Buscar" v-if="tab == 2"  style="border-radius: 10px;  height: 75%;">
                          <input v-model="buscador_ejecutivo" type="text" class="form-control mt-4" placeholder="Buscar" v-if="tab == 3"  style="border-radius: 10px;  height: 75%;">
                      </b-col>
                </b-row>
            </center>
            <br> <br v-if="!isMobile">
            <center v-if="loader">
                <SpinnerLoader/>
                <p style="color:#000;">Procesando información, no cierres el navegador <br> espera un momento...</p>
            </center>  
            <template v-if="tab == 0">   
                 <br>
                 <div :class="isMobile ? 'table-responsive' :''">
                        <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <!-- <td v-if="isMobile"></td>
                                <td :style="isMobile ? '' : 'width:5%; font-size:20px;  color:#0b2739;'"></td> -->
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:7%; font-size:20px;  color:#0b2739;'"  class="text-center"><b>Lugar</b></td>
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> 
                                <td v-if="isMobile" style="color:#0b2739;"><b>CAC</b></td> 
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? '' : 'width:12%;  font-size:20px;  color:#0b2739;'" class="text-center"><template v-if="!isMobile"><b>CAC</b></template></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile" style="color:#0b2739;"><b>IDPDV</b></td>
                                <td :style="isMobile ? ' ' : 'width:10%; font-size:20px;  color:#0b2739;'"><template v-if="!isMobile"><b>IDPDV</b></template></td>
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:12%; font-size:20px;'"><template v-if="!isMobile"><u>REGIÓN</u></template></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                   <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                               <td v-if="isMobile"  style=" color:#0b2739"><b> Cumplimiento</b> </td>
                                <td v-if="isMobile"></td>
                                   <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <!-- <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td  :style="isMobile ? 'color:#0b2739;' : 'width:40%;  font-size:20px;  color:#0b2739;'" class="text-center"><template v-if="!isMobile"><b>Cumplimiento</b> </template></td>
                                <!-- <td>DESAFÍOS</td> -->       
                                <td v-if="isMobile"></td>                         
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:10%;  font-size:20px;  color:#0b2739;'"><b>Destreza</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:10%;  font-size:20px;  color:#0b2739;'"><b>Capacitación</b></td>     
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>           
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:10%;  font-size:20px;  color:#0b2739;'"><b>Puntos</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr v-for="(ran, index) in gerente" :key="ran.id">
                               <td  class="text-center" :colspan="isMobile ? '2' : ''" >
                                    <template v-if="!buscar_gerente">
                                        <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:45%; margin-top:1px; ' 
                                        : 'width:92%; margin-top:-4px;' ">  
                                        <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                       <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px; ' : 'color: #fff; margin-top:7px;' " >
                                             {{index + 1  }}
                                        </h5>  
                                    </template>
                                    <template v-else>
                                        <img v-if="ran.lugar < 10" :src="'estrella/'+ (ran.lugar - 1)  + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:55%;  margin-top:-12px;'
                                         : 'width:92%; margin-top:-4px;' ">  
                                        <!-- <img v-if="ran.lugar > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                        <h4 v-if="ran.lugar > 9" :style="!isMobile ? 'color: #fff; margin-top:4px; ' : 'color: #fff; margin-top:7px;' " >
                                            {{ran.lugar}}
                                        </h4>  
                                    </template>
                                </td>
                                <!-- <td  class="text-left" :colspan="isMobile ? '2' : ''" >
                                    <template v-if="!ran.foto">
                                        <img  src="estrella/generico.png" :class=" isMobile ? 'rounded ml-3' : 'rounded'" alt="Image" :style="!isMobile ? 'width:45%; margin-top:-16px;' : 'width:95%; margin-top:-12px;' ">  
                                    </template>
                                    <template v-else>
                                        <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded  ml-3' : 'rounded'" alt="Image" 
                                        :style="!isMobile ? 'width:45%;  margin-top:-16px;' : 'width:95%; margin-top:-4px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                    </template>
                                </td> -->
                                <td :colspan="isMobile ? '8' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'" > 
                                    <div  :style="isMobile ? 'margin-top:10px;' : 'margin-top:7px;'">
                                     {{ran.tienda}}  
                                    </div>
                                </td>
                                <td :colspan="isMobile ? '3' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'" > 
                                    <div  :style="isMobile ? 'margin-top:10px;' : 'margin-top:7px;'">
                                       {{ran.idpdv}} 
                                    </div>
                                </td> 
                                <td :colspan="isMobile ? '13' : ''">
                                 <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                    <b style="font-size:13px;">
                                     <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px;'">
                                        <b-col cols="1"></b-col>
                                        <b-col cols="5">
                                            <b-row  >
                                                <b-col cols="3"  :style="isMobile ? 'margin-top:10px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                    <template v-else><br><br><br><span  style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:17px;'" class="mt-5" >
                                                        <b>{{ran.abril}}</b> 
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                                </b-col> 
                                                <b-col cols="3"  :style="isMobile ? 'margin-top:10px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>May</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:17px;'"  class="mt-5">
                                                        <b>{{ran.mayo}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                                </b-col>
                                                <b-col cols="3" :style="isMobile ? 'margin-top:10px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:17px;'"  class="mt-5">
                                                        <b>{{ran.junio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                                </b-col>
                                                <b-col cols="3" :style="isMobile ? 'margin-top:10px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;"><u>Jul</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.julio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                                </b-col> 
                                            </b-row>
                                        </b-col>
                                        <b-col cols="5"> 
                                         <b-row > 
                                           <b-col cols="3" :style="isMobile ? 'margin-top:10px;' : ''">
                                                <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;"><u>Agos</u></span><br></template>
                                                <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Agos</u></span><br></template>   
                                                <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                    <b>{{ran.agosto}}</b>
                                                </h4>
                                                <br>
                                                <br>
                                                <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                            </b-col>
                                            <b-col cols="3" :style="isMobile ? 'margin-top:10px;' : ''">
                                                <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;"><u>Sep</u></span><br></template>
                                                <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                    <b>{{ran.septiembre}}</b>
                                                </h4>
                                                <br>
                                                <br>
                                                <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                            </b-col> 
                                            <b-col cols="3" :style="isMobile ? 'margin-top:10px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;"><u>Oct</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.octubre}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                            </b-col>
                                            <b-col cols="3" :style="isMobile ? 'margin-top:10px;' : ''">
                                                <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;"><u>Nov</u></span><br></template>
                                                <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                    <b>{{ran.noviembre}}</b>
                                                </h4>
                                                <br>
                                                <br>
                                                <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                            </b-col>
                                            <!-- <b-col cols="1" :style="isMobile ? 'margin-top:10px;' : ''">
                                                <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;"><u>Dic</u></span><br></template>
                                                <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Dic</u></span><br></template>   
                                                <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                    <b>{{ran.diciembre}}</b>
                                                </h4>
                                                <br>
                                                <br>
                                                <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                            </b-col>  -->
                                            </b-row>
                                        </b-col> 
                                        <b-col cols="1"></b-col>
                                     </b-row>
                                    </b>
                                </div>  
                                </td> 
                                 <td :colspan="isMobile ? '3' : ''" :style="isMobile ? 'color: #fff; font-size:16px; ' : 'color: #fff; font-size:22px;'"> 
                                    <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                        <br v-if="isMobile">
                                        <b >{{ran.destreza}} </b> 
                                    </div>
                                </td>
                                <td :colspan="isMobile ? '3' : ''" :style="isMobile ? 'color: #fff; font-size:16px; ' : 'color: #fff; font-size:22px;'"> 
                                    <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                        <br v-if="isMobile">
                                        <b >{{ran.capacitacion}} </b> 
                                    </div>
                                </td>
                                <td  :colspan="isMobile ? '7' : ''"> 
                                    <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                        <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                        <b-col cols="10"  :style="isMobile ? 'margin-top:15px;' : ''">
                                            <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                            <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                            <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                        </b-col>
                                        </b-row>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>  
                        <br>
                        <h6 v-if=" vermas < arrayGerente2.length" style="cursor: pointer;" @click="getcampanas(vermas + 10)"><u>Ver más <b-spinner v-if="loader" small></b-spinner></u></h6>
                        <br>
                        <span v-if="vermas > 10 && !buscar_gerente" @click="subirArriba()" class="mdi mdi-arrow-up-thick " style="color:#fff; cursor: pointer;"> Regresar al Inicio</span> 
                 </div>     


            </template>
            <template v-if="tab == 3 ">
                 <br>
                 <div :class="isMobile ? 'table-responsive' :''">
                        <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <td v-if="isMobile"></td> 
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? 'color:#0b2739;' : 'width:3%; font-size:20px;  color:#0b2739;'" class="text-center" ><b>Lugar</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> 
                                <td v-if="isMobile" style="color:#0b2739;"><b>Ejecutivo</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? '' : 'width:12%;  font-size:20px;  color:#0b2739;'"><template v-if="!isMobile"><b>Ejecutivo</b></template></td>                       
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile" style="color:#0b2739;"><u><b>Región</b></u></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:10%; font-size:20px;  color:#0b2739;'"><template v-if="!isMobile"><b>Región</b></template></td> -->
                            <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:12%; font-size:20px;'"><template v-if="!isMobile"><u>REGIÓN</u></template></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                               <td v-if="isMobile"  style=" color:#0b2739"><b> Cumplimiento</b> </td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <!-- <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td  :style="isMobile ? 'color:#0b2739;' : 'width:25%;  font-size:20px;  color:#0b2739;'" class="text-center"><template v-if="!isMobile"><b>Cumplimiento</b> </template></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:5%;  font-size:20px;  color:#0b2739;'"><b>Destreza</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:5%;  font-size:20px;  color:#0b2739;'"><b>Capacitación</b></td> 
                                <td v-if="isMobile"></td>  
                                 <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>             
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:7%;  font-size:20px;  color:#0b2739;'"><b>Puntos</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr v-for="(ran, index) in ejecutivo" :key="ran.id">
                                 <td  class="text-left" :colspan="isMobile ? '4' : ''" >
                                    <b-row class="justify-content-left">
                                        <b-col cols="6" md="6" lg="5" >   
                                            <template v-if="!buscador_ejecutivo">
                                                <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:160%; margin-top:-6px; ' 
                                                : 'width:150%; margin-top:-10px;' ">  
                                                <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:-4px;' " > 
                                                        {{index + 1  }}
                                                </h5>  
                                            </template>
                                            <template v-else>
                                                <img v-if="ran.lugar < 10" :src="'estrella/'+ (ran.lugar - 1)  + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:160%;  margin-top:-6px;'
                                                : 'width:150%; margin-top:-10px;' ">  
                                                <!-- <img v-if="ran.lugar > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                <h4 v-if="ran.lugar > 9" :style="!isMobile ? 'color: #fff; margin-top:4px; ' : 'color: #fff; margin-top:-4px;' " >
                                                    {{ran.lugar}}
                                                </h4>  
                                            </template>
                                        </b-col> 
                                        <b-col cols="6" md="6" lg="5" >    
                                        <!-- </td>
                                        <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                            <template v-if="!ran.foto">
                                                <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded ml-3'" alt="Image" :style="!isMobile ? 'width:150%; margin-top:-4px;' 
                                                : 'width:130%; margin-top:-6px;' ">  
                                            </template>
                                            <template v-else>
                                                <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded  ml-3'" alt="Image" 
                                                :style="!isMobile ? 'width:150%;  margin-top:-4px;' : 'width:130%; margin-top:-10px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                            </template>
                                        </b-col>
                                    </b-row>
                                </td>
                                <td :colspan="isMobile ? '6' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 14px;'"> 
                                    <div  :style="isMobile ? 'margin-top:10px;' : 'margin-top:7px;'">
                                     {{ran.ejecutivo}}  
                                    </div>
                                </td>
                                <!-- <td :colspan="isMobile ? '6' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'"> 
                                    <div  :style="isMobile ? 'margin-top:10px;' : ''">
                                     {{ran.region}} 
                                    </div>
                                </td> -->
                                <td :colspan="isMobile ? '13' : ''">
                                 <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                    <b style="font-size:13px;">
                                        <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                          <b-col cols="1"></b-col>
                                          <b-col cols="5">
                                           <b-row>
                                                <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:17px;'" class="mt-5" >
                                                        <b>{{ran.abril}}</b> 
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                                </b-col>
                                                <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>May</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:17px;'"  class="mt-5">
                                                        <b>{{ran.mayo}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                </b-col>
                                                <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:17px;'"  class="mt-5">
                                                        <b>{{ran.junio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                </b-col>
                                                <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.julio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                </b-col>
                                            </b-row>
                                          </b-col>
                                          <b-col cols="5">
                                            <b-row> 
                                                <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.agosto}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                </b-col>
                                                <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.septiembre}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                </b-col> 
                                                <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.octubre}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                </b-col> 
                                                <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                    <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                    <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:17px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.noviembre}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                    <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                </b-col> 
                                            </b-row>
                                          </b-col>
                                          <b-col cols="1"></b-col>
                                        </b-row> 
                                    </b>
                                </div>  
                                </td> 
                                <td :colspan="isMobile ? '3' : ''"  :style="isMobile ? 'color: #fff; font-size:16px; ' : 'color: #fff; font-size:22px;'"> 
                                    <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                        <br v-if="isMobile">
                                        <b >{{ran.destreza}} </b> 
                                    </div>
                                </td>
                                <td :colspan="isMobile ? '3' : ''" :style="isMobile ? 'color: #fff; font-size:16px; ' : 'color: #fff; font-size:22px;'"> 
                                    <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                        <br v-if="isMobile">
                                        <b >{{ran.capacitacion}} </b> 
                                    </div>
                                </td>
                                <td  :colspan="isMobile ? '7' : ''"> 
                                    <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                        <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                        <b-col cols="10"  :style="isMobile ? 'margin-top:15px;' : ''">
                                            <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                            <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                            <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                        </b-col>
                                        </b-row>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                 </div> 
            </template>
            <template v-if="tab == 2 ">
                 <br>
                 <div :class="isMobile ? 'table-responsive' :''">
                        <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <td v-if="isMobile"></td> 
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? 'color:#0b2739;' : 'width:3%; font-size:20px;  color:#0b2739;'" class="text-center" ><b>Lugar</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> 
                                <td v-if="isMobile" style="color:#0b2739;"><b>Coordinador</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? '' : 'width:12%;  font-size:20px;  color:#0b2739;'"><template v-if="!isMobile"><b>Coordinador</b></template></td>                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile" style="color:#0b2739;"><u><b>Región</b></u></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:10%; font-size:20px;  color:#0b2739;'"><template v-if="!isMobile"><b>Región</b></template></td> -->
                            <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:12%; font-size:20px;'"><template v-if="!isMobile"><u>REGIÓN</u></template></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                               <td v-if="isMobile"  style=" color:#0b2739"><b> Cumplimiento</b> </td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                  <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <!-- <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td  :style="isMobile ? 'color:#0b2739;' : 'width:28%;  font-size:20px;  color:#0b2739;'" class="text-center"><template v-if="!isMobile"><b>Cumplimiento</b> </template></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:5%;  font-size:20px;  color:#0b2739;'"><b>Destreza</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:5%;  font-size:20px;  color:#0b2739;'"><b>Capacitación</b></td> 
                                <td v-if="isMobile"></td>  
                                 <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>             
                                <td :style="isMobile ? 'color:#0b2739; ' : 'width:7%;  font-size:20px;  color:#0b2739;'"><b>Puntos</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr v-for="(ran, index) in coordinador" :key="ran.id">
                                 <td  class="text-left" :colspan="isMobile ? '4' : ''" >
                                    <b-row class="justify-content-left">
                                        <b-col cols="6" md="6" lg="5" >   
                                            <template v-if="!buscar_coordinador">
                                                <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:160%; margin-top:-6px; ' 
                                                : 'width:150%; margin-top:-10px;' ">  
                                                <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:-4px;' " > 
                                                        {{index + 1  }}
                                                </h5>  
                                            </template>
                                            <template v-else>
                                                <img v-if="ran.lugar < 10" :src="'estrella/'+ (ran.lugar - 1)  + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:160%;  margin-top:-6px;'
                                                : 'width:150%; margin-top:-10px;' ">  
                                                <!-- <img v-if="ran.lugar > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                <h4 v-if="ran.lugar > 9" :style="!isMobile ? 'color: #fff; margin-top:4px; ' : 'color: #fff; margin-top:-4px;' " >
                                                    {{ran.lugar}}
                                                </h4>  
                                            </template>
                                        </b-col> 
                                        <b-col cols="6" md="6" lg="5" >    
                                        <!-- </td>
                                        <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                            <template v-if="!ran.foto">
                                                <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded ml-3'" alt="Image" :style="!isMobile ? 'width:150%; margin-top:-4px;' 
                                                : 'width:130%; margin-top:-6px;' ">  
                                            </template>
                                            <template v-else>
                                                <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded  ml-3'" alt="Image" 
                                                :style="!isMobile ? 'width:150%;  margin-top:-4px;' : 'width:130%; margin-top:-10px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                            </template>
                                        </b-col>
                                    </b-row>
                                </td>
                                <td :colspan="isMobile ? '6' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 14px;'"> 
                                    <div  :style="isMobile ? 'margin-top:10px;' : 'margin-top:7px;'">
                                     {{ran.coordinador}}  
                                    </div>
                                </td>
                                <!-- <td :colspan="isMobile ? '6' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'"> 
                                    <div  :style="isMobile ? 'margin-top:10px;' : ''">
                                     {{ran.region}} 
                                    </div>
                                </td> -->
                                <td :colspan="isMobile ? '13' : ''">
                                 <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                    <b style="font-size:13px;">
                                        <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                            <b-col cols="1"></b-col>
                                            <b-col cols="5">
                                                <b-row>
                                                    <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:22px;'" class="mt-5" >
                                                            <b>{{ran.abril}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile"> 
                                                    </b-col>
                                                    <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>May</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:22px;'"  class="mt-5">
                                                            <b>{{ran.mayo}}</b>
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                    </b-col>
                                                    <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:22px;'"  class="mt-5">
                                                            <b>{{ran.junio}}</b>
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                    </b-col>
                                                    <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'"  class="mt-5">
                                                            <b>{{ran.julio}}</b>
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                    </b-col> 
                                                </b-row>
                                            </b-col>
                                            <b-col cols="5">
                                               <b-row> 
                                                    <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'"  class="mt-5">
                                                            <b>{{ran.agosto}}</b>
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                    </b-col>
                                                    <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'"  class="mt-5">
                                                            <b>{{ran.septiembre}}</b>
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                    </b-col>
                                                    <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'"  class="mt-5">
                                                            <b>{{ran.octubre}}</b>
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                    </b-col>
                                                    <b-col cols="3" :style="isMobile ? 'margin-top:9px;' : ''">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'"  class="mt-5">
                                                            <b>{{ran.noviembre}}</b>
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="1"></b-col>
                                        </b-row>
                                    </b>
                                </div>  
                                </td> 
                                <td :colspan="isMobile ? '3' : ''"  :style="isMobile ? 'color: #fff; font-size:16px; ' : 'color: #fff; font-size:22px;'"> 
                                    <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                        <br v-if="isMobile">
                                        <b >{{ran.destreza}} </b> 
                                    </div>
                                </td>
                                <td :colspan="isMobile ? '3' : ''" :style="isMobile ? 'color: #fff; font-size:16px; ' : 'color: #fff; font-size:22px;'"> 
                                    <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                        <br v-if="isMobile">
                                        <b >{{ran.capacitacion}} </b> 
                                    </div>
                                </td>
                                <td  :colspan="isMobile ? '7' : ''"> 
                                    <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                        <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                        <b-col cols="10"  :style="isMobile ? 'margin-top:15px;' : ''">
                                            <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                            <b :style="isMobile ? 'font-size:16px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                            <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                        </b-col>
                                        </b-row>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                 </div> 
            </template>
            <br><br><br>
        </b-col>
     </b-row>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}
import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Propio',
 components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayGerente:[],
      arrayGerente2:[],
      arrayEjecutivo:[],
      arrayCoordinador:[],
      tab:0,
      colors:'#83c32d',
      buscar_coordinador:"",
      buscar_gerente:"",
      buscador_ejecutivo:"",
      vermas:10
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
    coordinador(){
     if (this.buscar_coordinador == '') {          
            return this.arrayCoordinador;
        }else{
               return   this.arrayCoordinador.filter(item => {
                    return   item.coordinador?.toLowerCase().includes(this.buscar_coordinador.toLowerCase()) ||
                             item.region?.toLowerCase().includes(this.buscar_coordinador.toLowerCase()) 
                    ;
              });
        }
    },
    gerente(){
     if (this.buscar_gerente == '') {          
            return this.arrayGerente;
        }else{
               return   this.arrayGerente2.filter(item => {
                    return   item.idpdv?.toLowerCase().includes(this.buscar_gerente.toLowerCase()) ||
                             item.tienda?.toLowerCase().includes(this.buscar_gerente.toLowerCase()) 
                    ;
              });
        }
    },
    ejecutivo(){
     if (this.buscador_ejecutivo == '') {          
            return this.arrayEjecutivo;
        }else{
               return   this.arrayEjecutivo.filter(item => {
                    return   item.ejecutivo?.toLowerCase().includes(this.buscador_ejecutivo.toLowerCase())
                    ;
              });
        }
    }
  },
  methods:{
    cambiaTab(id){
        this.tab = id; 
        this.buscar_gerente = ""; 
        this.buscar_coordinador = ""; 
        this.buscador_ejecutivo = ""; 
    },
    subirArriba(){
      // this.$scrollTo("#subir");
       window.scrollTo(0, -500);
    },
    getcampanas(id){
      this.loader = true;
      this.vermas = id;
      this.buscar_gerente = '';
      this.buscar_coordinador = ''; 
      this.buscador_ejecutivo = ""; 
      var url= 'auth/rankinpropio?vermas='+ id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayGerente = data.gerente.sort(compare); 
                this.arrayGerente2 = data.gerente2.sort(compare); 
                this.arrayCoordinador = data.coordinador.sort(compare);
                this.arrayEjecutivo = data.ejecutivo.sort(compare);
                this.loader = false;
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/"); 
    }
  },
  mounted(){
          if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo8", true)
        //  window.scrollTo(0, -200);     
      } 
    this.$bus.$emit("cambia_fondo", true)
    this.getcampanas(this.vermas);
  }
}
</script>
<style>
@media only screen and (min-width: 1000px) 
{
 
}
@media only screen and (min-width: 1300px) 
{
 
}
@media only screen and (min-width: 1400px)
{
 
}
@media only screen and (min-width: 1600px)
{
 
}
@media only screen and  (min-width: 1800px)
{
 
}   
</style>