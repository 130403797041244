<template>
  <div>   
    <br>
     <b-row class="justify-content-center">
        <b-col cols="11" md="11" lg="8" class="text-center">
            <center>
               <b-row class="justify-content-center">
                    <b-col cols="10" md="5" style="background: #29486F; border-radius: 20px;"  v-if="!isMobile">  
                      <b-row class="justify-content-center" style=" margin: 5px;" > 
                        <b-col cols="10" md="3" class="mt-2"  >  
                            <u style="color:#fff; font-size:14px; cursor: pointer;" @click="cambiaTab(0)">KAMS</u>
                        </b-col>
                        <b-col cols="10" md="3" class="mt-2" >  
                            <u style="color:#fff; font-size:14px; cursor: pointer;" @click="cambiaTab(1)">LÍDER</u>
                        </b-col> 
                        <b-col cols="10" md=""  class="mt-1">  
                          <input v-model="buscar_lider" type="text" class="form-control" placeholder="Buscar" v-if="tab == 1" style="border-radius: 10px; height: 90%;">
                          <input v-model="buscar_kams" type="text" class="form-control" placeholder="Buscar"  v-if="tab == 0"  style="border-radius: 10px; height: 90%;">  
                        </b-col> 
                      </b-row>  
                    </b-col>
                     <b-col cols="6" md="4"  v-if="isMobile">
                        <v-btn :color="tab == 0 ? '#29486F' : '#1975B8'" :class="isMobile ? 'mt-4' : 'mt-2'" size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaTab(0)"> 
                           KAMs
                        </v-btn>
                      </b-col> 
                     <b-col cols="6" md="4"  v-if="isMobile">
                        <v-btn :color="tab == 1 ? '#29486F' : '#1975B8'" :class="isMobile ? 'mt-4' : 'mt-2'" size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaTab(1)"> 
                           Líder
                        </v-btn>
                     </b-col>
                      <b-col cols="8" md="4" v-if="isMobile">
                         <input v-model="buscar_kams" type="text" class="form-control mt-4" placeholder="Buscar" v-if="tab == 0" style="border-radius: 10px; height: 75%;">
                         <input v-model="buscar_lider" type="text" class="form-control mt-4" placeholder="Buscar"  v-if="tab == 1"  style="border-radius: 10px; height: 75%;"> 
                      </b-col>
                </b-row>
            </center>
            <br><br>
            <center v-if="loader">
                <SpinnerLoader/>
                <p style="color:#000;">Procesando información, no cierres el navegador <br> espera un momento...</p>
            </center>
            <br>
            <template v-if="tab == 0">  
                <div :class="isMobile ? 'table-responsive' :''">
                        <table class="table table-borderless mt-3 text-center">
                        <thead class="text-white">
                        <tr>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td  :style="isMobile ? 'color:#0b2739;' : 'width:3%; font-size:20px;  color:#0b2739;'"  class="text-center"><b>Lugar</b></td> 
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td> 
                            <td v-if="isMobile"  style="color:#0b2739;"><b>KAMs</b></td>  
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td :style="isMobile ? '' : 'width:8%;  font-size:20px;  color:#0b2739;'"><template v-if="!isMobile"><b>KAMs</b></template></td>
                            <!-- <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile">REGIÓN</td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td :style="isMobile ? ' ' : 'width:12%; font-size:20px;'"><template v-if="!isMobile"><u>REGIÓN</u></template></td> -->
                            <!-- <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td> -->
                            <!-- <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td> -->
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                               <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                               <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"  style=" color:#0b2739"><b> Cumplimiento</b> </td>
                            <td v-if="isMobile"></td>
                               <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                               <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <!-- <td v-if="isMobile"></td> -->
                            <!-- <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td> -->
                            <!-- <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td> -->
                            <td  :style="isMobile ? 'color:#0b2739;' : 'width:16%;  font-size:20px;  color:#0b2739;'" class="text-center"><template v-if="!isMobile"><b>Cumplimiento</b> </template></td>
                            <!-- <td>DESAFÍOS</td> -->                                
                            <!-- <td :style="isMobile ? 'color:#0b2739; ' : 'width:6%;  font-size:20px;  color:#0b2739;'"><u><b>Destreza</b></u></td>        -->
                            <td v-if="!isMobile" style="width:1%;"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td> 
                            <td :style="isMobile ? 'color:#0b2739; ' : 'width:6%;  font-size:20px;  color:#0b2739;'"><b>Puntos</b></td>
                            <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td> 
                            <td v-if="isMobile"></td> 
                        </tr>
                        </thead>
                        <tbody>
                        <br>
                        <tr  v-for="(ran, index) in Kams" :key="ran.id + '-lider'">
                            <td  class="text-right" :colspan="isMobile ? '4' : ''" >
                                <b-row class="justify-content-center">
                                     <b-col cols="6" md="3" lg="5" >   
                                        <template v-if="!buscar_kams">
                                            <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:130%; margin-top:-12px; ' 
                                            : 'width:150%; margin-top:-14px;' ">  
                                            <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                            <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:-4px;' " > 
                                                    {{index + 1  }}
                                            </h5>  
                                        </template>
                                        <template v-else>
                                            <img v-if="ran.lugar < 10" :src="'estrella/'+ (ran.lugar - 1)  + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:130%;  margin-top:-12px;'
                                             : 'width:150%; margin-top:-14px;' ">  
                                            <!-- <img v-if="ran.lugar > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                            <h4 v-if="ran.lugar > 9" :style="!isMobile ? 'color: #fff; margin-top:4px; ' : 'color: #fff; margin-top:-4px;' " >
                                                {{ran.lugar}}
                                            </h4>  
                                        </template>
                                    </b-col>
                                    <b-col cols="6" md="3" lg="5" >    
                                    <!-- </td>
                                    <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                        <template v-if="!ran.foto">
                                            <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:110%; margin-top:-6px;' : 'width:130%; margin-top:-10px;' ">  
                                        </template>
                                        <template v-else>
                                            <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded'" alt="Image" 
                                            :style="!isMobile ? 'width:100%;  margin-top:-14px;' : 'width:130%; margin-top:-1px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                        </template>
                                    </b-col>
                                </b-row>
                            </td>
                            <td :colspan="isMobile ? '8' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'"> 
                                 <div  :style="isMobile ? 'margin-top:-2px;' : 'margin-top:7px;'">
                                  {{ran.kams}} 
                                 </div>
                            </td> 
                            <td :colspan="isMobile ? '13' : ''">
                                <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                    <b style="font-size:13px;">
                                        <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                            <b-col cols="1"></b-col>
                                            <b-col cols="5">
                                                <b-row>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                            <b>{{ran.abril}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">  
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>May</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                            <b>{{ran.mayo}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">  
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                            <b>{{ran.junio}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">  
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                            <b>{{ran.julio}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">  
                                                    </b-col> 
                                                </b-row>
                                            </b-col>
                                            <b-col cols="5">
                                                <b-row> 
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                            <b>{{ran.agosto}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">  
                                                    </b-col>
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                            <b>{{ran.septiembre}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">  
                                                    </b-col> 
                                                   <b-col cols="3">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                            <b>{{ran.octubre}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">  
                                                    </b-col> 
                                                    <b-col cols="3">
                                                        <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                        <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                        <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                            <b>{{ran.noviembre}}</b> 
                                                        </h4>
                                                        <br>
                                                        <br>
                                                        <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">   <br v-if="isMobile">  
                                                    </b-col> 
                                                </b-row>
                                            </b-col>
                                            <b-col cols="1"></b-col>
                                        </b-row>
                                    </b>
                                </div>  
                            </td> 
                        <!-- <td  :style="isMobile ? 'color: #fff; font-size:15px; ' : 'color: #fff; font-size:22px;'"> 
                                <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                    <br v-if="isMobile">
                                    <b >{{ran.destreza}} </b> 
                                </div>
                            </td>  -->
                            <td v-if="!isMobile"></td>
                            <td  :colspan="isMobile ? '7' : ''"> 
                                <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                    <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                    <b-col cols="10"  :style="isMobile ? 'margin-top:1px;' : ''">
                                        <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                        <b :style="isMobile ? 'font-size:15px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                        <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                    </b-col>
                                    </b-row>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <br>
                    <h6 v-if="Kams.length > 9 && vermas < arrayKams2.length" style="cursor: pointer;" @click="getcampanas(vermas + 10)"><u>Ver más <b-spinner v-if="loader" small></b-spinner></u></h6>
                    <br>
                    <span v-if="vermas > 10 && !buscar_agente" @click="subirArriba()" class="mdi mdi-arrow-up-thick " style="color:#fff; cursor: pointer;"> Regresar al Inicio</span> 
                </div> 
            </template>
            <template v-if="tab == 1"> 
                <div :class="isMobile ? 'table-responsive' :''">
                         <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td  :style="isMobile ? 'color:#0b2739;' : 'width:3%; font-size:20px;  color:#0b2739;'"  class="text-center"><b>Lugar</b></td> 
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> 
                                <td v-if="isMobile"  style="color:#0b2739;"><b>Líder</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> 
                                <td :style="isMobile ? '' : 'width:8%;  font-size:20px;  color:#0b2739;'"><template v-if="!isMobile"><b>Líder</b></template></td> 
                               <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile">REGIÓN</td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td :style="isMobile ? ' ' : 'width:12%; font-size:20px;'"><template v-if="!isMobile"><u>REGIÓN</u></template></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                   <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                               <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                               <td v-if="isMobile"  style=" color:#0b2739"><b> Cumplimiento</b> </td>
                                <td v-if="isMobile"></td>
                                   <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                               <td v-if="isMobile"></td>
                            <td v-if="isMobile"></td>
                                <!-- <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <!-- <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> -->
                                <td  :style="isMobile ? 'color:#0b2739;' : 'width:16%;  font-size:20px;  color:#0b2739;'" class="text-center"><template v-if="!isMobile"><b>Cumplimiento</b> </template></td>
                                <td v-if="!isMobile" style="width:1%;"></td>
                                <!-- <td>DESAFÍOS</td> -->                                
                                <!-- <td :style="isMobile ? 'color:#0b2739; ' : 'width:6%;  font-size:20px;  color:#0b2739;'"><u><b>Destreza</b></u></td>        -->
                                <!-- <td :style="isMobile ? 'color:#0b2739; ' : 'width:6%;  font-size:20px;  color:#0b2739;'"><u><b>Capacitación</b></u></td>         -->
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td>
                                 <td v-if="isMobile"></td> 
                                 <td :style="isMobile ? 'color:#0b2739; ' : 'width:6%;  font-size:20px;  color:#0b2739;'"><b>Puntos</b></td>
                                <td v-if="isMobile"></td>
                                <td v-if="isMobile"></td> 
                                <td v-if="isMobile"></td> 
                               
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr  v-for="(ran, index) in Lider" :key="ran.id + '-lider'">
                               <td  class="text-right" :colspan="isMobile ? '4' : ''" >
                                   <b-row class="justify-content-center">
                                        <b-col cols="6" md="3" lg="5" >   
                                            <template v-if="!buscar_lider">
                                                <img v-if="index < 10" :src="'estrella/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:130%; margin-top:-12px; ' 
                                                : 'width:150%; margin-top:-18px;' ">  
                                                <!-- <img v-if="index > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                <h5 v-if="index > 9" :style="!isMobile ? 'color: #fff; margin-top:4px;' : 'color: #fff; margin-top:-4px;' " > 
                                                        {{index + 1  }}
                                                </h5>  
                                            </template>
                                            <template v-else>
                                                <img v-if="ran.lugar < 10" :src="'estrella/'+ (ran.lugar - 1)  + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:130%;  margin-top:-12px;' 
                                                : 'width:150%; margin-top:-18px;' ">  
                                                <!-- <img v-if="ran.lugar > 3" src="estrella/generico.png" class="rounded" alt="Image" :style="!isMobile ? 'width:40%;  margin-top:-10px;' : 'width:80%; margin-top:2px;'">    -->
                                                <h4 v-if="ran.lugar > 9" :style="!isMobile ? 'color: #fff; margin-top:4px; ' : 'color: #fff; margin-top:-4px;' " >
                                                    {{ran.lugar}}
                                                </h4>  
                                            </template>
                                        </b-col>
                                        <b-col  cols="6" md="3" lg="5">   
                                        <!-- </td>
                                        <td  class="text-left" :colspan="isMobile ? '2' : ''" > -->
                                            <template v-if="!ran.foto">
                                                <img  src="estrella/generico.png" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" :style="!isMobile ? 'width:110%; margin-top:-6px;'
                                                 : 'width:130%; margin-top:-15px;' ">  
                                            </template>
                                            <template v-else>
                                                <b-img :src="$api.baseUriFiles+'perfil/' + ran.foto" rounded="circle"   :class=" isMobile ? 'rounded ' : 'rounded'" alt="Image" 
                                                :style="!isMobile ? 'width:100%;  margin-top:-14px;' : 'width:130%; margin-top:-1px;' "  :height="!isMobile ? 55 : 50">  </b-img>
                                            </template>
                                        </b-col>
                                    </b-row>
                                </td>
                                <td :colspan="isMobile ? '8' : ''" :style="isMobile ? 'color: #fff; font-size: 14px;' : 'color: #fff; font-size: 16px;'"> 
                                     <div  :style="isMobile ? 'margin-top:-2px;' : 'margin-top:7px;'">
                                      {{ran.lider}} 
                                     </div>  
                                </td> 
                                <td :colspan="isMobile ? '13' : ''">
                                    <div style="color: #fff; background: #2684f0; border-radius: 19px; "> 
                                        <b style="font-size:13px;">
                                            <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-95px'">
                                                <b-col cols="1"></b-col>
                                                <b-col cols="5">
                                                    <b-row>
                                                        <b-col cols="3">
                                                            <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Abr</u></span><br></template>
                                                            <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Abr</u></span><br></template>   
                                                            <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                <b>{{ran.abril}}</b> 
                                                            </h4>
                                                            <br>
                                                            <br>
                                                            <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">  
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>May</u></span><br></template>
                                                            <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>May</u></span><br></template>   
                                                            <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                <b>{{ran.mayo}}</b> 
                                                            </h4>
                                                            <br>
                                                            <br>
                                                            <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">  
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;"><u>Jun</u></span><br></template>
                                                            <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739;" v-if="index == 0"><u>Jun</u></span><br></template>   
                                                            <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:22px;'" class="mt-4" >
                                                                <b>{{ran.junio}}</b> 
                                                            </h4>
                                                            <br>
                                                            <br>
                                                            <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">  
                                                        </b-col>
                                                    <b-col cols="3">
                                                            <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Jul</u></span><br></template>
                                                            <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Jul</u></span><br></template>   
                                                            <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                <b>{{ran.julio}}</b> 
                                                            </h4>
                                                            <br>
                                                            <br>
                                                            <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">  
                                                        </b-col> 
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="5">
                                                    <b-row> 
                                                        <b-col cols="3">
                                                            <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Ago</u></span><br></template>
                                                            <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Ago</u></span><br></template>   
                                                            <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                <b>{{ran.agosto}}</b> 
                                                            </h4>
                                                            <br>
                                                            <br>
                                                            <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">  
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Sep</u></span><br></template>
                                                            <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Sep</u></span><br></template>   
                                                            <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                <b>{{ran.septiembre}}</b> 
                                                            </h4>
                                                            <br>
                                                            <br>
                                                            <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">  
                                                        </b-col> 
                                                        <b-col cols="3">
                                                            <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Oct</u></span><br></template>
                                                            <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Oct</u></span><br></template>   
                                                            <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                <b>{{ran.octubre}}</b> 
                                                            </h4>
                                                            <br>
                                                            <br>
                                                            <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">  
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <template v-if="isMobile"><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;"><u>Nov</u></span><br></template>
                                                            <template v-else><br><br><br><span style="text-transform:capitalize; font-size:15px;  color:#0b2739; margin-left: -10px;" v-if="index == 0"><u>Nov</u></span><br></template>   
                                                            <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:22px; margin-left: -10px;'" class="mt-4" >
                                                                <b>{{ran.noviembre}}</b> 
                                                            </h4>
                                                            <br>
                                                            <br>
                                                            <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  <br v-if="isMobile">  
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="1"></b-col>
                                            </b-row>
                                        </b>
                                    </div>  
                                </td> 
                                <td v-if="!isMobile"></td>
                                <!-- <td  :style="isMobile ? 'color: #fff; font-size:15px; ' : 'color: #fff; font-size:22px;'"> 
                                    <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                        <br v-if="isMobile">
                                        <b >{{ran.destreza}} </b> 
                                    </div>
                                </td>  -->
                               <!-- <td  :style="isMobile ? 'color: #fff; font-size:15px; ' : 'color: #fff; font-size:22px;'"> 
                                    <div  :style="isMobile ? 'margin-top:-15px;' : ''">
                                        <br v-if="isMobile">
                                        <b >{{ran.capacitacion}} </b> 
                                    </div>
                                </td> -->
                               <td  :colspan="isMobile ? '7' : ''"> 
                                    <div style="color: #fff; background: #6ac5f9;  border-radius: 19px;">
                                        <b-row class="justify-content-center" :style="isMobile ? 'margin-top:-80px;' : 'margin-top:-100px'">
                                        <b-col cols="10"  :style="isMobile ? 'margin-top:1px;' : ''">
                                            <br v-if="!isMobile"><br v-if="!isMobile"><br><br><br v-if="isMobile">  
                                            <b :style="isMobile ? 'font-size:15px;'  : 'font-size: 22px;'">{{ran.total}} </b> 
                                            <br > <br > <br><br v-if="isMobile"><br v-if="isMobile"> <br v-if="isMobile"> <br v-if="isMobile">  
                                        </b-col>
                                        </b-row>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                    <br>
                    <h6 v-if="Lider.length > 9 &&  vermas < arrayLider2.length"  style="cursor: pointer;" @click="getcampanas(vermas + 10)"><u>Ver más <b-spinner v-if="loader" small></b-spinner></u></h6>
                    <br>
                    <span v-if="vermas > 10 && !buscar_lider" @click="subirArriba()" class="mdi mdi-arrow-up-thick " style="color:#fff; cursor: pointer;"> Regresar al Inicio</span> 
                </div> 
            </template>
        </b-col>
     </b-row>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}

import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Propio',
  components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayLider:[],
      arrayKams:[], 
      arrayLider2:[],
      arrayKams2:[], 
      tab:0,
      colors:'#83c32d',
      buscar_lider:"",
      buscar_kams:"", 
      vermas:10
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
    Kams(){
        if (this.buscar_kams == '') {          
                return this.arrayKams;
            }else{
                return   this.arrayKams2.filter(item => {
                        return   item.kams?.toLowerCase().includes(this.buscar_kams.toLowerCase())
                        ;
                });
            }
    },
    Lider(){
        if (this.buscar_lider == '') {          
                return this.arrayLider;
            }else{
                return   this.arrayLider2.filter(item => {
                        return   item.lider?.toLowerCase().includes(this.buscar_lider.toLowerCase())
                        ;
                });
            }
    }, 
  },
  methods:{
    cambiaTab(id){
        this.tab = id;
        this.buscar_kams = "";
        this.buscar_lider = "";
    },
    subirArriba(){
      // this.$scrollTo("#subir");
       window.scrollTo(0, -500);
    },
    getcampanas(id){
      this.loader = true;
      this.vermas = id;
      this.buscar_kams = '';
      this.buscar_lider = ''; 
      var url= 'auth/rankinespecialista?vermas='+ id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayLider = data.lider.sort(compare); 
                this.arrayLider2 = data.lider2.sort(compare); 
                this.arrayKams = data.kams.sort(compare); 
                this.arrayKams2 = data.kams2.sort(compare); 
                this.loader = false;
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/"); 
    }
  },
  mounted(){
    this.$bus.$emit("cambia_fondo", true)
    this.getcampanas(this.vermas);
  }
}
</script>
<style>
.flexbox {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}
</style>